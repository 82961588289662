import CryptoJS from "crypto-js";
import React from "react";
import Image from "next/image";
import Link from "next/link";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function AesDecrypt(data: any) {
  const key = "B?E(H+MbQeThWmZq4t7w!z$C&F)J@NcR";
  const iv = "AEF$#DQER@#R@#f3";
  var reb64 = CryptoJS.enc.Hex.parse(data);
  var bytes = reb64?.toString(CryptoJS.enc.Base64);
  let cipher = CryptoJS.AES.decrypt(bytes, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return cipher.toString(CryptoJS.enc.Utf8);
}

export function AesEncrypt(data: any) {
  const key = "B?E(H+MbQeThWmZq4t7w!z$C&F)J@NcR";
  const iv = "AEF$#DQER@#R@#f3";
  let cipher = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    CryptoJS.enc.Utf8.parse(key),
    {
      iv: CryptoJS.enc.Utf8.parse(iv), // parse the IV
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }
  );

  // e.g. B6AeMHPHkEe7/KHsZ6TW/Q==
  let b64 = cipher.toString();
  let e64 = CryptoJS.enc.Base64.parse(b64);
  let eHex = e64.toString(CryptoJS.enc.Hex);
  return eHex;
}

export const funcForDecrypt = async (dataToBeDecrypt: any) => {
  const response = AesDecrypt(dataToBeDecrypt);
  //
  return response;
};

export const funForAesEncrypt = async (dataToBeEncrypt: any) => {
  const response = await AesEncrypt(dataToBeEncrypt);
  //
  return response;
};

export function ParseFloat(str: any, val: any) {
  str = str.toString();
  if (Number.isInteger(Number(str))) {
    return Number(str);
  } else {
    str = str.slice(0, str.indexOf(".") + val + 1);
  }
  return Number(str);
}

export function formatString(inputString: string): string {
  return inputString
    .toLowerCase()
    .split(" ")
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const Card = ({ title, description, imageUrl, linkTo }: any) => {
  const formattedDescription = description.replace(/LIMITED/g, "LIMITED<br />");

  return (
    <Link href={linkTo}>
      <div className="py-6 sm:min-h-[110px] overflow-hidden flex flex-col items-center">
        <Image
          className="mb-1"
          src={imageUrl}
          alt={title}
          width={30}
          height={30}
          style={{
            maxWidth: "100%",
            height: "auto"
          }} />
        <div className="bold text-base mt-2 text-white">{title}</div>
        <div
          className="px-4 py-2 text-center text-white"
          dangerouslySetInnerHTML={{ __html: formattedDescription }}
        />
      </div>
    </Link>
  );
};

type InputType = "text" | "number" | "email" | "password" | "date" | "textarea" | "tel";
interface InputProps {
  type?: InputType;
  autoComplete?: string;
  required?: boolean;
  label?: string;
  formik: any;
  name: string;
  onblur?: any;
  placeholder?: any;
  extra?: object;
}

const FormInput = ({
  type,
  autoComplete,
  required,
  label,
  name,
  formik,
  placeholder,
  onblur,
  extra,
}: InputProps) => {
  return (
    <div className={styles.p0}>
      {label && (
        <label htmlFor="email" className={styles.p1}>
          {label}
        </label>
      )}
      <div className={styles.p4}>
        <input
          id={name}
          name={name}
          type={type || "text"}
          onChange={formik.handleChange}
          onBlur={onblur}
          value={formik.values[name]}
          autoComplete={autoComplete}
          required={required || false}
          placeholder={placeholder || `${label}${required ? "" : ""}`}
          className={styles.p2}
          {...extra}
        />
        {formik.touched[name] && formik.errors[name] ? (
          <span className={styles.p3}>{formik.errors[name]}</span>
        ) : null}
      </div>
    </div>
  );
};
const styles = {
  p0: "mb-2",
  p1: "block text-sm mb-2 text-white ",
  p2: "block w-full  placeholder:text-gray-500 text-white rounded-3xl bg-theme  px-4 py-2 focus:outline-none focus:bg-theme  border-1  focus:ring-0 focus:border-b",
  // p2: "appearance-none placeholder:text-gray-500 block w-full sm:w-2/3 px-3 py-2 border-0 rounded-md theme-shadow placeholder-gray-400 focus:border-yellow-600 focus:ring-1 focus:ring-yellow-600 sm:text-sm",
  p3: "text-red-500 text-sm",
  p4: "mt-1",
};
export default FormInput;

const displayValue = (lable: any) => {
  switch (lable) {
    case 'Coin':
      return "Coin Type"
    case 'Coin Weight':
      return "Total Weight"
    case 'Coin Quantity':
      return "Coin Quantity"
    case "Making Charges (Incl. 18% GST)":
      return "Making Charges (Incl. 18% GST)"
    default:
      return lable;
  }

}

export const renderPriceBreakdownItem = ({ label, value, index }: any) => (
  <>
    <div key={index} className="mb-2 flex justify-between">
      <span>{displayValue(label)}</span>
      <span>{value}</span>
    </div>
    <hr
      className="my-1 border-t border-gray-300"
      style={{
        backgroundImage:
          "linear-gradient(to right, #d2d6dc 50%, transparent 50%)",
        backgroundSize: "0.3rem 2px",
        backgroundRepeat: "repeat-x",
        border: "none",
        height: "1px",
      }}
    />
  </>
);

export const renderPriceBreakdownItemCart = ({
  label,
  value,
  index,
  imageUrl,
}: any) => (
  <div>
    <div className="justify-between flex items-center">
      <div key={index} className="mb-1 flex items-center">
        {imageUrl && (
          <div className="mr-1">
            <img src={imageUrl} alt={label} className="w-10 h-10" />
          </div>
        )}
        <span className="tracking-wide text-gray-300">{label}</span>
      </div>
      <div>
        <span className="text-gray-100 extrabold tracking-wide">
          {value}
        </span>
      </div>
    </div>
    <hr
      className="my-0 border-t border-gray-400"
      style={{
        backgroundImage:
          "linear-gradient(to right, #d2d6dc 50%, transparent 50%)",
        backgroundSize: "0.4rem 2px",
        backgroundRepeat: "repeat-x",
        border: "none",
        height: "1px",
      }}
    />
  </div>
);


export const getGoldProgressData = (vaultBalance: number) => {
  const capAt100 = (value: number) => Math.min(value, 100); // Helper function to cap percentage at 100

  if (vaultBalance <= 1.5) {
    // 0 to 1.5 grams, closest to 1 gram
    const percentage = capAt100(Math.round((vaultBalance / 1) * 100));
    return { completedGold: percentage, maxCompletedGold: 100, customLabelGold: `1 gm Gold Coin`, id: '1-Gram-Gold-Coin' };
  } else if (vaultBalance > 1.5 && vaultBalance < 2.5) {
    // Greater than 1.5 to less than 2.5 grams, closest to 2 grams
    const percentage = capAt100(Math.round((vaultBalance / 2) * 100));
    return { completedGold: percentage, maxCompletedGold: 100, customLabelGold: `2 gm Gold Coin`, id: '2-Gram-Gold-Coin' };
  } else if (vaultBalance >= 2.5 && vaultBalance < 5) {
    // Greater than 2.5 to less than 5 grams, closest to 5 grams
    const percentage = capAt100(Math.round(((vaultBalance) / 3) * 100));
    return { completedGold: percentage, maxCompletedGold: 100, customLabelGold: `5 gm Gold Coin`, id: '5-Gram-Gold-Coin' };
  } else if (vaultBalance >= 5 && vaultBalance < 7.5) {
    // Greater than 5 to less than 7.5 grams, closest to 5 grams
    return { completedGold: 100, maxCompletedGold: 100, customLabelGold: `5 gm Gold Coin`, id: '10-Gram-Gold-Coin' };
  } else if (vaultBalance >= 7.5 && vaultBalance < 10) {
    // Greater than 7.5 to less than 10 grams, closest to 10 grams
    const percentage = capAt100(Math.round(((vaultBalance) / 10) * 100));
    return { completedGold: percentage, maxCompletedGold: 100, customLabelGold: `10 gm Gold Coin`, id: '10-Gram-Gold-Coin' };
  } else {
    // 10 grams or more
    return { completedGold: 100, maxCompletedGold: 100, customLabelGold: `10 gm Gold Coin`, id: '10-Gram-Gold-Coin' };
  }
};


export const getSilverProgressData = (silverVaultBalance: number) => {
  const capAt100 = (value: number) => Math.min(value, 100); // Helper to cap percentage at 100

  if (silverVaultBalance <= 10.5) {
    // 0 to 10.5 grams, closest to 10 grams
    const percentage = capAt100(Math.round((silverVaultBalance / 10) * 100));
    return { completedSilver: percentage, maxCompletedSilver: 100, customLabelSilver: `10 gm Silver Coin`, SilverId: '10-Gram-Silver-Coin' };
  } else if (silverVaultBalance > 10.5 && silverVaultBalance < 20.5) {
    // Greater than 10.5 to less than 20.5 grams, closest to 20 grams
    const percentage = capAt100(Math.round((silverVaultBalance / 20) * 100));
    return { completedSilver: percentage, maxCompletedSilver: 100, customLabelSilver: `20 gm Silver Coin`, SilverId: '20-Gram-Silver-Coin' };
  } else if (silverVaultBalance >= 20.5 && silverVaultBalance < 50) {
    // Greater than 20.5 to less than 50 grams, closest to 50 grams
    const percentage = capAt100(Math.round(((silverVaultBalance) / 50) * 100));
    return { completedSilver: percentage, maxCompletedSilver: 100, customLabelSilver: `50 gm Silver Coin`, SilverId: '50-Gram-Silver-Coin' };
  } else if (silverVaultBalance >= 50 && silverVaultBalance < 75) {
    // Greater than 50 to less than 75 grams, closest to 50 grams
    return { completedSilver: 100, maxCompletedSilver: 100, customLabelSilver: `50 gm Silver Coin`, SilverId: '50-Gram-Silver-Coin' };
  } else if (silverVaultBalance >= 75 && silverVaultBalance < 100.5) {
    // Greater than 75 to less than 100.5 grams, closest to 100 grams
    const percentage = capAt100(Math.round(((silverVaultBalance ) / 100) * 100));
    return { completedSilver: percentage, maxCompletedSilver: 100, customLabelSilver: `100 gm Silver Coin`, SilverId: '100-Gram-Silver-Coin' };
  } else {
    // 100 grams or more
    return { completedSilver: 100, maxCompletedSilver: 100, customLabelSilver: `100 gm Silver Coin`, SilverId: '100-Gram-Silver-Coin' };
  }
};

export const goldPriceTrends = [
  { date: "2023-11-07T23:59:30.919Z", price: 6090.2 },
  { date: "2023-11-12T03:36:30.536Z", price: 6117.17 },
  { date: "2023-11-22T23:59:30.371Z", price: 6196.86 },
  { date: "2023-11-30T23:56:30.484Z", price: 6303.44 },
  { date: "2023-12-02T23:58:30.705Z", price: 6428.2 },
  { date: "2023-12-05T23:50:31.124Z", price: 6324.59 },
  { date: "2023-12-13T23:59:30.568Z", price: 6259 },
  { date: "2023-12-19T23:59:31.145Z", price: 6340.81 },
  { date: "2023-12-28T23:59:31.005Z", price: 6410.53 },
  { date: "2023-12-31T23:59:30.878Z", price: 6408.9 },
  { date: "2024-01-04T23:59:31.135Z", price: 6372.23 },
  { date: "2024-01-13T23:59:31.068Z", price: 6309.53 },
  { date: "2024-01-17T23:59:30.786Z", price: 6293.04 },
  { date: "2024-01-25T23:59:30.438Z", price: 6285.47 },
  { date: "2024-01-28T23:59:30.539Z", price: 6327.23 },
  { date: "2024-02-02T23:59:30.752Z", price: 6361.8 },
  { date: "2024-02-08T23:59:31.038Z", price: 6332.99 },
  { date: "2024-02-16T23:59:30.867Z", price: 6269.8 },
  { date: "2024-02-18T23:59:30.664Z", price: 6300.27 },
  { date: "2024-02-28T23:59:30.611Z", price: 6321.4 },
  { date: "2024-03-01T23:59:31.175Z", price: 6450 },
  { date: "2024-03-03T23:59:30.916Z", price: 6597.17 },
  { date: "2024-03-12T23:59:30.428Z", price: 6661.24 },
  { date: "2024-03-18T23:59:31.074Z", price: 6667 },
  { date: "2024-03-29T23:59:30.498Z", price: 6772.91 },
  { date: "2024-03-31T23:59:31.166Z", price: 6863 },
  { date: "2024-04-04T23:59:30.427Z", price: 7058.8 },
  { date: "2024-04-13T23:59:30.958Z", price: 7226.76 },
  { date: "2024-04-18T23:59:31.399Z", price: 7345.74 },
  { date: "2024-04-21T23:59:31.237Z", price: 7237.01 },
  { date: "2024-04-28T23:59:30.794Z", price: 7208.33 },
  { date: "2024-05-03T23:59:30.975Z", price: 7160.18 },
  { date: "2024-05-07T23:59:30.550Z", price: 7252.41 },
  { date: "2024-05-12T23:59:30.752Z", price: 7382.07 },
  { date: "2024-05-24T23:59:30.547Z", price: 7365.36 },
  { date: "2024-05-27T23:59:30.826Z", price: 7284.87 },
  { date: "2024-06-01T23:59:31.240Z", price: 7275 },
  { date: "2024-06-07T23:59:30.490Z", price: 7295.87 },
  { date: "2024-06-09T23:59:31.075Z", price: 7250.13 },
  { date: "2024-06-19T23:59:31.220Z", price: 7271.09 },
  { date: "2024-06-23T23:59:31.223Z", price: 7242.37 },
  { date: "2024-06-30T23:59:30.882Z", price: 7248.5 },
  { date: "2024-07-04T23:59:30.930Z", price: 7323.35 },
  { date: "2024-07-08T23:59:30.738Z", price: 7381.33 },
  { date: "2024-07-20T23:59:31.275Z", price: 7452.71 },
  { date: "2024-07-25T23:59:31.257Z", price: 7047.14 },
  { date: "2024-07-31T23:59:31.197Z", price: 6970.6 },
  { date: "2024-08-03T23:59:30.339Z", price: 7075.03 },
  { date: "2024-08-05T23:59:31.167Z", price: 7038.26 },
  { date: "2024-08-11T23:59:30.362Z", price: 7152.5 },
  { date: "2024-08-19T23:59:30.692Z", price: 7251.99 },
  { date: "2024-08-27T23:59:30.492Z", price: 7278.69 },
  { date: "2024-09-03T23:59:30.670Z", price: 7247.26 },
  { date: "2024-09-12T23:59:00.941Z", price: 7329.66 },
  { date: "2024-09-15T23:59:01.200Z", price: 7442.33 },
  { date: "2024-09-22T23:59:01.228Z", price: 7597.03 },
  { date: "2024-09-30T23:59:00.907Z", price: 7656.35 },
  { date: "2024-10-03T23:59:00.937Z", price: 7716 },
  { date: "2024-10-11T23:59:00.432Z", price: 7665.71 },
  { date: "2024-10-15T23:59:00.778Z", price: 7776.66 },
  { date: "2024-10-22T23:59:01.186Z", price: 7915.87 },
  { date: "2024-10-30T23:59:00.834Z", price: 7981.22 },
  { date: "2024-11-01T20:46:00.394Z", price: 7967.5 }
];

export const silverPriceTrends = [
  { date: "2023-11-07T23:59:30.919Z", price: 71.4 },
  { date: "2023-11-12T03:36:30.536Z", price: 73.8 },
  { date: "2023-11-22T23:59:30.371Z", price: 74.3 },
  { date: "2023-11-30T23:56:30.484Z", price: 75.6 },
  { date: "2023-12-02T23:58:30.705Z", price: 76.9 },
  { date: "2023-12-05T23:50:31.124Z", price: 78.1 },
  { date: "2023-12-13T23:59:30.568Z", price: 77.5 },
  { date: "2023-12-19T23:59:31.145Z", price: 79.3 },
  { date: "2023-12-28T23:59:31.005Z", price: 81.7 },
  { date: "2023-12-31T23:59:30.878Z", price: 80.5 },
  { date: "2024-01-04T23:59:31.135Z", price: 79.9 },
  { date: "2024-01-13T23:59:31.068Z", price: 78.6 },
  { date: "2024-01-17T23:59:30.786Z", price: 79.1 },
  { date: "2024-01-25T23:59:30.438Z", price: 80.8 },
  { date: "2024-01-28T23:59:30.539Z", price: 81.2 },
  { date: "2024-02-02T23:59:30.752Z", price: 83.4 },
  { date: "2024-02-08T23:59:31.038Z", price: 82.6 },
  { date: "2024-02-16T23:59:30.867Z", price: 84.9 },
  { date: "2024-02-18T23:59:30.664Z", price: 85.3 },
  { date: "2024-02-28T23:59:30.611Z", price: 86.2 },
  { date: "2024-03-01T23:59:31.175Z", price: 88.9 },
  { date: "2024-03-03T23:59:30.916Z", price: 89.6 },
  { date: "2024-03-12T23:59:30.428Z", price: 90.4 },
  { date: "2024-03-18T23:59:31.074Z", price: 91.8 },
  { date: "2024-03-29T23:59:30.498Z", price: 93.2 },
  { date: "2024-03-31T23:59:31.166Z", price: 95.7 },
  { date: "2024-04-04T23:59:30.427Z", price: 94.3 },
  { date: "2024-04-13T23:59:30.958Z", price: 96.4 },
  { date: "2024-04-18T23:59:31.399Z", price: 98.2 },
  { date: "2024-04-21T23:59:31.237Z", price: 97.1 },
  { date: "2024-04-28T23:59:30.794Z", price: 96.8 },
  { date: "2024-05-03T23:59:30.975Z", price: 95.4 },
  { date: "2024-05-07T23:59:30.550Z", price: 94.7 },
  { date: "2024-05-12T23:59:30.752Z", price: 97.5 },
  { date: "2024-05-24T23:59:30.547Z", price: 96.3 },
  { date: "2024-05-27T23:59:30.826Z", price: 98.1 },
  { date: "2024-06-01T23:59:31.240Z", price: 97.8 },
  { date: "2024-06-07T23:59:30.490Z", price: 96.5 },
  { date: "2024-06-09T23:59:31.075Z", price: 94.2 },
  { date: "2024-06-19T23:59:31.220Z", price: 93.7 },
  { date: "2024-06-23T23:59:31.223Z", price: 92.4 },
  { date: "2024-06-30T23:59:30.882Z", price: 91.9 },
  { date: "2024-07-04T23:59:30.930Z", price: 92.6 },
  { date: "2024-07-08T23:59:30.738Z", price: 93.3 },
  { date: "2024-07-20T23:59:31.275Z", price: 95.8 },
  { date: "2024-07-25T23:59:31.257Z", price: 97.4 },
  { date: "2024-07-31T23:59:31.197Z", price: 96.2 },
  { date: "2024-08-03T23:59:30.339Z", price: 95.5 },
  { date: "2024-08-05T23:59:31.167Z", price: 93.8 },
  { date: "2024-08-11T23:59:30.362Z", price: 92.1 },
  { date: "2024-08-19T23:59:30.692Z", price: 90.5 },
  { date: "2024-08-27T23:59:30.492Z", price: 89.7 },
  { date: "2024-09-03T23:59:30.670Z", price: 88.4 },
  { date: "2024-09-12T23:59:00.941Z", price: 86.9 },
  { date: "2024-09-15T23:59:01.200Z", price: 84.6 },
  { date: "2024-09-22T23:59:01.228Z", price: 82.3 },
  { date: "2024-09-30T23:59:00.907Z", price: 80.8 },
  { date: "2024-10-03T23:59:00.937Z", price: 81.6 },
  { date: "2024-10-11T23:59:00.432Z", price: 83.9 },
  { date: "2024-10-15T23:59:00.778Z", price: 84.3 },
  { date: "2024-10-22T23:59:01.186Z", price: 85.1 },
  { date: "2024-10-30T23:59:00.834Z", price: 87.6 },
  { date: "2024-11-01T20:46:00.394Z", price: 88.4 }
];


export const rawData = [
  {
      "updated_on": "28 Oct, 2024, 11:41 ",
      "status": "Shipment delivered",
      "reason_code": "999 - Delivered",
      "reason_code_number": "999",
      "scan_status": "HOLD",
      "location": "MSDO",
      "location_city": "SHAHDOL - SDO",
      "location_type": "Service Center",
      "city_name": "SHAHDOL - SDO",
      "Employee": "SURESH PRASAD RAJAK  - DP180033"
  },
  {
      "updated_on": "28 Oct, 2024, 10:01 ",
      "status": "Shipment out for delivery, assigned to EmployeeRavi sondhiya",
      "reason_code": " - ",
      "reason_code_number": "006",
      "scan_status": "OUT",
      "location": "MSDO",
      "location_city": "SHAHDOL - SDO",
      "location_type": "Service Center",
      "city_name": "SHAHDOL - SDO",
      "Employee": "Ravi  sondhiya - PT23994"
  },
  {
      "updated_on": "28 Oct, 2024, 07:29 ",
      "status": "Shipment in-scan at DC",
      "reason_code": " - ",
      "reason_code_number": "005",
      "scan_status": "IN",
      "location": "MSDO",
      "location_city": "SHAHDOL - SDO",
      "location_type": "Service Center",
      "city_name": "SHAHDOL - SDO",
      "Employee": "Ravi  sondhiya - PT23994"
  },
  {
      "updated_on": "28 Oct, 2024, 06:18 ",
      "status": "Bag scanned at DC",
      "reason_code": " - ",
      "reason_code_number": "003",
      "scan_status": "IN",
      "location": "MSDO",
      "location_city": "SHAHDOL - SDO",
      "location_type": "Service Center",
      "city_name": "SHAHDOL - SDO",
      "Employee": "Ravi  sondhiya - PT23994"
  },
  {
      "updated_on": "28 Oct, 2024, 00:03 ",
      "status": "In-Transit",
      "reason_code": " - ",
      "reason_code_number": "003",
      "scan_status": "HOLD",
      "location": "KAH",
      "location_city": "KATNI - KAT",
      "location_type": "Hub",
      "city_name": "KATNI - KAT",
      "Employee": "KAPIL KUMAR GADARI - 99035"
  },
  {
      "updated_on": "28 Oct, 2024, 00:03 ",
      "status": "In-Transit",
      "reason_code": " - ",
      "scan_status": "HOLD",
      "location": "KAH",
      "location_city": "KATNI - KAT",
      "location_type": "Hub",
      "city_name": "KATNI - KAT",
      "Employee": "KAPIL KUMAR GADARI - 99035"
  },
  {
      "updated_on": "27 Oct, 2024, 20:02 ",
      "status": "Bag scanned at Hub",
      "reason_code": " - ",
      "reason_code_number": "003",
      "scan_status": "IN",
      "location": "KAH",
      "location_city": "KATNI - KAT",
      "location_type": "Hub",
      "city_name": "KATNI - KAT",
      "Employee": "AJEET KUMAR - 99050"
  },
  {
      "updated_on": "27 Oct, 2024, 02:46 ",
      "status": "In-Transit",
      "reason_code": " - ",
      "reason_code_number": "003",
      "scan_status": "HOLD",
      "location": "INH",
      "location_city": "INDORE - IND",
      "location_type": "Hub",
      "city_name": "INDORE - IND",
      "Employee": "OM Prakash - 131315"
  },
  {
      "updated_on": "27 Oct, 2024, 02:45 ",
      "status": "In-Transit",
      "reason_code": " - ",
      "scan_status": "HOLD",
      "location": "INH",
      "location_city": "INDORE - IND",
      "location_type": "Hub",
      "city_name": "INDORE - IND",
      "Employee": "OM Prakash - 131315"
  },
  {
      "updated_on": "26 Oct, 2024, 04:22 ",
      "status": "Bag scanned at Hub",
      "reason_code": " - ",
      "reason_code_number": "003",
      "scan_status": "IN",
      "location": "INH",
      "location_city": "INDORE - IND",
      "location_type": "Hub",
      "city_name": "INDORE - IND",
      "Employee": "Lakhan Chouhan - O430607"
  },
  {
      "updated_on": "26 Oct, 2024, 04:19 ",
      "status": "Shipment connected to MSDO (Bag No. PL80363762)",
      "reason_code": " - ",
      "reason_code_number": "003",
      "scan_status": "HOLD",
      "location": "INP",
      "location_city": "INDORE - IND",
      "location_type": "Processing Centre",
      "city_name": "INDORE - IND",
      "Employee": "Aman  Kumar - O482327"
  },
  {
      "updated_on": "25 Oct, 2024, 19:40 ",
      "status": "Shipment in-scan",
      "reason_code": " - ",
      "reason_code_number": "002",
      "scan_status": "IN",
      "location": "INP",
      "location_city": "INDORE - IND",
      "location_type": "Processing Centre",
      "city_name": "INDORE - IND",
      "Employee": "INP FWD Sorter - CB312217"
  },
  {
      "updated_on": "25 Oct, 2024, 14:38 ",
      "status": "Bag scanned at DC",
      "reason_code": " - ",
      "reason_code_number": "003",
      "scan_status": "IN",
      "location": "INP",
      "location_city": "INDORE - IND",
      "location_type": "Processing Centre",
      "city_name": "INDORE - IND",
      "Employee": "Rohit Malviya - 124620"
  },
  {
      "updated_on": "25 Oct, 2024, 14:38 ",
      "status": "Bag scanned at DC",
      "reason_code": " - ",
      "reason_code_number": "003",
      "scan_status": "IN",
      "location": "INP",
      "location_city": "INDORE - IND",
      "location_type": "Processing Centre",
      "city_name": "INDORE - IND",
      "Employee": "Rohit Malviya - 124620"
  },
  {
      "updated_on": "25 Oct, 2024, 09:05 ",
      "status": "Bag scanned at Hub",
      "reason_code": " - ",
      "reason_code_number": "003",
      "scan_status": "IN",
      "location": "INH",
      "location_city": "INDORE - IND",
      "location_type": "Hub",
      "city_name": "INDORE - IND",
      "Employee": "Sandeep Songara - O453338"
  },
  {
      "updated_on": "24 Oct, 2024, 10:11 ",
      "status": "In-Transit",
      "reason_code": " - ",
      "reason_code_number": "003",
      "scan_status": "HOLD",
      "location": "BH4",
      "location_city": "GURUGRAM - GGN",
      "location_type": "Hub",
      "city_name": "GURUGRAM - GGN",
      "Employee": "Inderjeet . - 67382"
  },
  {
      "updated_on": "24 Oct, 2024, 10:11 ",
      "status": "In-Transit",
      "reason_code": " - ",
      "scan_status": "HOLD",
      "location": "BH4",
      "location_city": "GURUGRAM - GGN",
      "location_type": "Hub",
      "city_name": "GURUGRAM - GGN",
      "Employee": "Inderjeet . - 67382"
  },
  {
      "updated_on": "24 Oct, 2024, 05:57 ",
      "status": "Bag scanned at Hub",
      "reason_code": " - ",
      "reason_code_number": "003",
      "scan_status": "IN",
      "location": "BH4",
      "location_city": "GURUGRAM - GGN",
      "location_type": "Hub",
      "city_name": "GURUGRAM - GGN",
      "Employee": "BH4  Sorter - CB312250"
  },
  {
      "updated_on": "24 Oct, 2024, 01:59 ",
      "status": "In-Transit",
      "reason_code": " - ",
      "reason_code_number": "003",
      "scan_status": "HOLD",
      "location": "DEH",
      "location_city": "DELHI - DEL",
      "location_type": "Hub",
      "city_name": "DELHI - DEL",
      "Employee": "DEEPAK KUMAR - 79006"
  },
  {
      "updated_on": "24 Oct, 2024, 01:59 ",
      "status": "In-Transit",
      "reason_code": " - ",
      "scan_status": "HOLD",
      "location": "DEH",
      "location_city": "DELHI - DEL",
      "location_type": "Hub",
      "city_name": "DELHI - DEL",
      "Employee": "DEEPAK KUMAR - 79006"
  },
  {
      "updated_on": "24 Oct, 2024, 00:46 ",
      "status": "Bag scanned at Hub",
      "reason_code": " - ",
      "reason_code_number": "003",
      "scan_status": "IN",
      "location": "DEH",
      "location_city": "DELHI - DEL",
      "location_type": "Hub",
      "city_name": "DELHI - DEL",
      "Employee": "Noor  Hasan - 90983"
  },
  {
      "updated_on": "24 Oct, 2024, 00:35 ",
      "status": "Shipment connected to INP (Bag No. SE49534888)",
      "reason_code": " - ",
      "reason_code_number": "003",
      "scan_status": "HOLD",
      "location": "DEP",
      "location_city": "DELHI - DEL",
      "location_type": "Processing Centre",
      "city_name": "DELHI - DEL",
      "Employee": "ASHOK PAL - O540391"
  },
  {
      "updated_on": "23 Oct, 2024, 23:03 ",
      "status": "Shipment in-scan",
      "reason_code": " - ",
      "reason_code_number": "002",
      "scan_status": "IN",
      "location": "DJP",
      "location_city": "DELHI - DEL",
      "location_type": "Processing Centre",
      "city_name": "DELHI - DEL",
      "Employee": "DJP FWD Sorter - CB312260"
  },
  {
      "updated_on": "23 Oct, 2024, 13:31 ",
      "status": "Shipment Picked Up",
      "reason_code": " - ",
      "reason_code_number": "0011",
      "scan_status": "HOLD",
      "location": "DJR",
      "location_city": "DELHI - DEL",
      "location_type": "Service Center",
      "city_name": "DELHI - DEL",
      "Employee": "JAFAR ALI - 92852"
  },
  {
      "updated_on": "23 Oct, 2024, 10:47 ",
      "status": "Out for Pickup",
      "reason_code": " - ",
      "reason_code_number": "1230",
      "scan_status": "HOLD",
      "location": "DJR",
      "location_city": "DELHI - DEL",
      "location_type": "Service Center",
      "city_name": "DELHI - DEL",
      "Employee": "JAFAR ALI - 92852"
  },
  {
      "updated_on": "23 Oct, 2024, 10:27 ",
      "status": "Pickup Assigned",
      "reason_code": " - ",
      "reason_code_number": "1220",
      "scan_status": "HOLD",
      "location": "DJR",
      "location_city": "DELHI - DEL",
      "location_type": "Service Center",
      "city_name": "DELHI - DEL",
      "Employee": "SAURABH . - 90745"
  },
  {
      "updated_on": "23 Oct, 2024, 09:55 ",
      "status": "Pickup Assigned",
      "reason_code": " - ",
      "reason_code_number": "1220",
      "scan_status": "HOLD",
      "location": "DJR",
      "location_city": "DELHI - DEL",
      "location_type": "Service Center",
      "city_name": "DELHI - DEL",
      "Employee": "JAFAR ALI - 92852"
  },
  {
      "updated_on": "22 Oct, 2024, 16:17 ",
      "status": "Soft data uploaded",
      "reason_code": " - ",
      "reason_code_number": "001",
      "scan_status": "HOLD",
      "location": "DJR",
      "location_city": "DELHI - DEL",
      "location_type": "Service Center",
      "city_name": "DELHI - DEL",
      "Employee": "None"
  }
]

export const rtoLock = [
  {
    "updated_on": "28 Oct, 2024, 10:56",
    "status": "Soft data uploaded",
    "reason_code": "-",
    "reason_code_number": "001",
    "scan_status": "HOLD",
    "location": "DJR",
    "location_city": "DELHI - DEL",
    "location_type": "Service Center",
    "city_name": "DELHI - DEL",
    "Employee": "None"
  },
  {
    "updated_on": "09 Nov, 2024, 01:18",
    "status": "Soft data uploaded",
    "reason_code": "326 - Shipment Cancelled after Max unsuccessful pickup attempts",
    "reason_code_number": "001",
    "scan_status": "HOLD",
    "location": "DJR",
    "location_city": "DELHI - DEL",
    "location_type": "Service Center",
    "city_name": "DELHI - DEL",
    "Employee": "None"
  },
  {
    "updated_on": "09 Nov, 2024, 01:18",
    "status": "Shipment RTO Lock",
    "reason_code": "-",
    "reason_code_number": "1330",
    "scan_status": "HOLD",
    "location": "DJR",
    "location_city": "DELHI - DEL",
    "location_type": "Service Center",
    "city_name": "DELHI - DEL",
    "Employee": "None"
  }
]
